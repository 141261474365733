/* MOBILE */
#nav-toggle, .nav--mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .header--ads {
    display: block;
    float: none;
    text-align: center;
    margin: 5px auto 20px auto;
  }
  .header--logo {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 850px) {
  #main--content {
    width: 100%;
    padding-right: 0;
    float: none;
  }
  #sidebar {
    width: 100%;
    float: none;
  }
  #sidebar .half {
    width: 50%;
    float: left;
    padding: 0px 5px;
  }

  #menu-nav-footer {
    text-align: center;
  }
  #menu-nav-footer li {
    margin: 5px 10px;
  }
}

@media screen and (max-width: 705px) {
  .contest--highlight ul {
    display: block;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .contest--highlight .btn {
    float: none;
    position: static;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    width: 48%;
    vertical-align: top;
  }
  .contest--highlight .btn.btn--more {
    float: right;
  }
  .single-contests .contest--highlight .btn.btn--submit {
    width: 100%;
  }

  .nav--main li {
    display: block;
    padding: 10px 0;
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .contest--highlight .btn,
  .contest--highlight .btn.btn--more {
    float: none;
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .nav--holder {
    display: none;
  }
  #nav-toggle {
    display: inline-block;
    float: right;
    width: 28px;
    height: 30px;
    padding: 38px 0 0 10px;
    cursor: pointer;
  }
  #nav-toggle span:after,
  #nav-toggle span:before {
    content: "";
    position: absolute;
    left: 0;
    top: -9px;
  }
  #nav-toggle span:after {
    top: 9px;
  }
  #nav-toggle span {
    position: relative;
    display: block;
  }
  #nav-toggle span,
  #nav-toggle span:after,
  #nav-toggle span:before {
    width: 100%;
    height: 5px;
    background-color: #888;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px;
  }

  .nav--mobile {
    margin-bottom: 15px;
  }
  .nav--mobile li {
    display: block;
    text-align: center;
  }
  .nav--mobile li a {
    display: block;
    padding: 10px 0;
    height: 100%;
  }

  #sidebar .half {
    width: 100%;
    float: none;
  }

  .register-form,
  .login-form,
  #wppb-recover-password-button {
    width: 100%;
    float: none;
    margin: 15px 0 30px 0;
  }
}