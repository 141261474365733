/*

NAVS & PAGINATION

 */

.nav {
  text-transform: uppercase;
  color: $strong-grey;

  li {
    display: inline-block;
  }

}

.nav--header {
  display: inline-block;
  margin: 20px 0;

  li {
    margin-right: 25px;
  }
}

.nav--contest {
  display: inline-block;
  margin: 20px 0;
  float: right;
  color: $main-red;
  font-weight: bold;
}

.nav--main {
  background: $nav-grey;
  padding: 5px 0 17px 0;
  font-weight: bold;
  font-size: 14px;

  li {
    margin: 15px 10px 0 25px;
  }

  &:before {
    clear: both;
    content: "";
    display: block;
    width: 100%;
  }

}

.pagination {
  display: block;
  margin: auto;

}

.pagination--btn-wrapper {

  .pagination {
    display: block;
    margin: auto;

  }
}


