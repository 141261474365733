/* NEWSLETTER STYLINGS */
.wf-formTpl {
  background: $light-grey;
  padding: 30px 20px;
  margin-bottom: 30px;
  text-align: center;
}

.wf-name, .wf-email label, .wf-counter, .wf-captcha, .wf-privacy, .wf-poweredby {
  display: none;
}

h2.newsletter-form-header-title strong {
  color: #444;
}

.wf-body, .wf-email {
  margin-top: 20px;
}

.wf-email .wf-inputpos input {
  padding: 10px;
  width: 100%;
  text-align: center;
  color: #c1c1c1;
}

.wf-email .wf-inputpos input:focus {
  color: $strong-grey;
}

.wf-submit .wf-inputpos input {
  color: white;
  text-align: center;
  padding: 15px 30px;
  background: $main-red;
  border: none;
  width: 150px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

/* ACF STYLINGS */
#message.updated {
  background: #39ca4e;

}

#message.updated p {
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  margin: 0;
}

.acf-form-submit {
  padding: 15px 12px;
}

.acf-form-submit .button {
  /*    border: none;*/
  margin-bottom: 20px;
}

.grwf2-wrapper.wf2-embedded {
  margin-bottom: 40px;
  display: inline-block;
}

