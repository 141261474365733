
/*

FOOTER

 */


#footer {
  clear: both;
  display: block;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #d0d0d0;
  margin-top: 30px;
  color: $strong-grey;
  font-size: 12px;
}

.footer--nav {
  text-transform: none;
  margin-bottom: 15px;

  li {
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
      font-weight: bold;
      color: $main-red;
      text-transform: uppercase;
    }
  }
}

