/*

HEADER

 */

#header {
  margin-bottom: 40px;
}

.header--logo {
  display: inline-block;
  height: 90px;
  padding: 0;
  border: none;

  img {
    max-height: 55px;
    height: auto;
    padding: 15px 0 20px 0;
    width: auto;
  }

  &.no-img {
    font-size: 24px;
    display: table;

    span {
      display: table-cell;
      vertical-align: middle;
    }

  }

  &:before {
    content: "";
    clear: both;
    display: block;
    width: 100%;
  }
}

.header--ads {
  display: inline-block;
  float: right;
  /*    height: 90px;*/
}
