/*

BLOG STYLINGS

*/

.post--stream {
  .post {
    padding-bottom: 20px;
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: 20px;

    &:last-child {
      border-bottom: none;

    }
  }

}

.post--single {
  margin-bottom: 30px;
}

.post--thumbnail {
  margin-bottom: 15px;

}

.post--pagination {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pagination .nav-links {
  text-align: center;
  margin-bottom: 5px;
}

.page-numbers {
  background: $nav-grey;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px;
  margin-top: 3px;
  margin-bottom: 3px;
  box-sizing: border-box;
  display: inline-block;
}

.page-numbers.current {
  color: $main-red;
}

a.page-numbers:hover {
  background: $main-red;
  color: white;
}

.code-block {
  width: 100%;
  min-height: 100px;
}