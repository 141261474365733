@charset "UTF-8";
/*!
Theme Name: Guru V2
Description: Theme for running photo contests
Author: Patryk Pawłowski
Author URI: http://www.patryk.me/
Bitbucket Theme URI: https://panwron@bitbucket.org/panwron/guru-v2.git
Bitbucket Branch:    master
Version: 1.12
*/
/*
VARS
- colors
- fonts
- structure
 */
/*
GLOBALS
  - reset
  - body and main structure layout
  - common elements (links, btns, imgs)
  - forms
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*
-------------------------
body and main structure |
-------------------------
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F2F2;
  font-family: "Open Sans", sans-serif; }

.container-wrap {
  background: white;
  max-width: 1300px;
  width: 100%;
  padding: 0;
  margin: 0 auto; }

.container {
  max-width: 1000px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto; }

#main--content {
  box-sizing: border-box;
  width: 70%;
  padding-right: 30px;
  float: left; }

#sidebar {
  width: 30%;
  float: right; }

/*
-----------------
common elements |
-----------------
 */
a {
  text-decoration: none;
  color: inherit; }
  a:hover, a:focus {
    color: #b30004;
    outline: none; }

img {
  max-width: 100%; }

strong {
  color: #b30004;
  font-weight: bold; }

p {
  margin-bottom: 1em; }

div {
  box-sizing: border-box; }

.btn, .button, .button-primary {
  background: #b30004;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px;
  box-sizing: border-box;
  display: inline-block; }
  .btn:hover, .btn:focus, .button:hover, .button:focus, .button-primary:hover, .button-primary:focus {
    background: #b30004;
    color: white; }

.button, .button-primary {
  background: white;
  color: #b30004;
  border: 2px solid #b30004; }

/*
----------
forms     |
----------
 */
.forms {
  margin-top: 20px; }

.register-form, .login-form, #wppb-recover-password-button {
  width: 48%;
  margin: 0 1%;
  float: left;
  vertical-align: top; }

.submit.button, .button-primary {
  text-align: center;
  width: 100%; }

input {
  padding: 3px 5px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 15px; }

/*

Typography

 */
body {
  font-family: "Open Sans", sans-serif;
  color: #7e7e7e;
  font-size: 13px;
  line-height: 18px; }

h1, h2, h3, h4, h5 {
  font-weight: 700;
  line-height: 1; }

h1 {
  font-size: 18px;
  text-transform: uppercase;
  color: #444444;
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 25px; }

h2 {
  font-size: 16px;
  color: #b30004;
  margin-bottom: 10px; }

h3 {
  font-size: 13px;
  color: #737272; }

h4 {
  font-size: 13px;
  color: #b30004; }

.date {
  color: #000;
  font-size: 11px;
  line-height: 1; }

/*

NAVS & PAGINATION

 */
.nav {
  text-transform: uppercase;
  color: #444444; }
  .nav li {
    display: inline-block; }

.nav--header {
  display: inline-block;
  margin: 20px 0; }
  .nav--header li {
    margin-right: 25px; }

.nav--contest {
  display: inline-block;
  margin: 20px 0;
  float: right;
  color: #b30004;
  font-weight: bold; }

.nav--main {
  background: #F7F7F7;
  padding: 5px 0 17px 0;
  font-weight: bold;
  font-size: 14px; }
  .nav--main li {
    margin: 15px 10px 0 25px; }
  .nav--main:before {
    clear: both;
    content: "";
    display: block;
    width: 100%; }

.pagination {
  display: block;
  margin: auto; }

.pagination--btn-wrapper .pagination {
  display: block;
  margin: auto; }

/*

HEADER

 */
#header {
  margin-bottom: 40px; }

.header--logo {
  display: inline-block;
  height: 90px;
  padding: 0;
  border: none; }
  .header--logo img {
    max-height: 55px;
    height: auto;
    padding: 15px 0 20px 0;
    width: auto; }
  .header--logo.no-img {
    font-size: 24px;
    display: table; }
    .header--logo.no-img span {
      display: table-cell;
      vertical-align: middle; }
  .header--logo:before {
    content: "";
    clear: both;
    display: block;
    width: 100%; }

.header--ads {
  display: inline-block;
  float: right;
  /*    height: 90px;*/ }

/*

SIDEBAR


 */
.sidebar--ads {
  width: 100%;
  margin: 20px 0;
  text-align: center; }

/*

FOOTER

 */
#footer {
  clear: both;
  display: block;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid #d0d0d0;
  margin-top: 30px;
  color: #444444;
  font-size: 12px; }

.footer--nav {
  text-transform: none;
  margin-bottom: 15px; }
  .footer--nav li {
    margin-right: 25px; }
    .footer--nav li:last-child {
      margin-right: 0;
      font-weight: bold;
      color: #b30004;
      text-transform: uppercase; }

/*

Contest


 */
.showontop {
  border: 2px solid #b30004;
  padding: 15px;
  margin-bottom: 20px; }

.contest--excerpt {
  margin-top: 20px; }

.contest--logo {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  float: left; }

.contest--content a:hover {
  color: black; }

.contest--highlight {
  background: #F7F7F7;
  padding: 15px;
  margin: 15px 0; }
  .contest--highlight ul {
    display: inline-block;
    max-width: calc(100% - 200px); }
    .contest--highlight ul li {
      line-height: 1;
      padding: 2px 0; }

.contest--highlight .btn {
  float: right; }
  .contest--highlight .btn.btn--more {
    margin-right: 10px;
    background: #F7F7F7;
    border: 2px solid #b30004;
    padding: 13px 20px;
    box-sizing: border-box;
    color: #b30004; }
    .contest--highlight .btn.btn--more:hover {
      background: #b30004;
      color: white; }

.contest--recommended .contest--content {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d0d0d0; }
  .contest--recommended .contest--content:last-child {
    border-bottom: none; }

.contest--recommended .contest--logo {
  width: 125px;
  height: 125px;
  margin-right: 20px; }

.contest--recommended .contest--excerpt:after {
  content: "";
  clear: both;
  width: 100%;
  display: block; }

.contest--new .contest--content {
  margin-bottom: 15px; }

.contest--new .contest--logo {
  width: 50px;
  height: 50px;
  margin-right: 10px; }

.contest--new .contest--title {
  font-weight: normal; }

.contest--new .contest--teaser:after {
  content: "";
  clear: both;
  width: 100%;
  display: block; }

.contest--closing .date {
  margin-bottom: 2px; }

.contest--closing .contest--title {
  font-size: 13px; }

.contest--full .contest--teaser {
  font-size: 13px;
  margin-top: 0; }

.contest--full .contest--logo {
  width: 250px;
  margin: 0 20px 15px 0; }

.contest--listing .contest--logo {
  width: 125px;
  height: 125px;
  margin: 0 20px 15px 0; }

.contest--listing .contest--content:after {
  content: "";
  clear: both;
  display: block;
  width: 100%; }

.contest--listing .contest--highlight {
  margin: 0; }

.contest--listing .contest--wrap {
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 20px; }
  .contest--listing .contest--wrap:last-child {
    border-bottom: none; }

.pagination .nav-links .page-numbers.dots {
  padding-left: 0;
  padding-right: 0;
  background: none; }

/* CONTEST FINDER STYLINGS */
#search-query {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 20px; }

.search-query-items {
  margin-top: 5px;
  margin-bottom: 10px; }

.search-query-items li {
  display: inline; }

#search-btn {
  margin-bottom: 20px; }

/*

BLOG STYLINGS

*/
.post--stream .post {
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 20px; }
  .post--stream .post:last-child {
    border-bottom: none; }

.post--single {
  margin-bottom: 30px; }

.post--thumbnail {
  margin-bottom: 15px; }

.post--pagination {
  margin-top: 30px;
  margin-bottom: 30px; }

.pagination .nav-links {
  text-align: center;
  margin-bottom: 5px; }

.page-numbers {
  background: #F7F7F7;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px;
  margin-top: 3px;
  margin-bottom: 3px;
  box-sizing: border-box;
  display: inline-block; }

.page-numbers.current {
  color: #b30004; }

a.page-numbers:hover {
  background: #b30004;
  color: white; }

.code-block {
  width: 100%;
  min-height: 100px; }

/* NEWSLETTER STYLINGS */
.wf-formTpl {
  background: #F2F2F2;
  padding: 30px 20px;
  margin-bottom: 30px;
  text-align: center; }

.wf-name, .wf-email label, .wf-counter, .wf-captcha, .wf-privacy, .wf-poweredby {
  display: none; }

h2.newsletter-form-header-title strong {
  color: #444; }

.wf-body, .wf-email {
  margin-top: 20px; }

.wf-email .wf-inputpos input {
  padding: 10px;
  width: 100%;
  text-align: center;
  color: #c1c1c1; }

.wf-email .wf-inputpos input:focus {
  color: #444444; }

.wf-submit .wf-inputpos input {
  color: white;
  text-align: center;
  padding: 15px 30px;
  background: #b30004;
  border: none;
  width: 150px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer; }

/* ACF STYLINGS */
#message.updated {
  background: #39ca4e; }

#message.updated p {
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  margin: 0; }

.acf-form-submit {
  padding: 15px 12px; }

.acf-form-submit .button {
  /*    border: none;*/
  margin-bottom: 20px; }

.grwf2-wrapper.wf2-embedded {
  margin-bottom: 40px;
  display: inline-block; }

/* MOBILE */
#nav-toggle, .nav--mobile {
  display: none; }

@media screen and (max-width: 1000px) {
  .header--ads {
    display: block;
    float: none;
    text-align: center;
    margin: 5px auto 20px auto; }
  .header--logo {
    margin-bottom: 0px; } }

@media screen and (max-width: 850px) {
  #main--content {
    width: 100%;
    padding-right: 0;
    float: none; }
  #sidebar {
    width: 100%;
    float: none; }
  #sidebar .half {
    width: 50%;
    float: left;
    padding: 0px 5px; }
  #menu-nav-footer {
    text-align: center; }
  #menu-nav-footer li {
    margin: 5px 10px; } }

@media screen and (max-width: 705px) {
  .contest--highlight ul {
    display: block;
    max-width: 100%;
    margin-bottom: 10px; }
  .contest--highlight .btn {
    float: none;
    position: static;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    width: 48%;
    vertical-align: top; }
  .contest--highlight .btn.btn--more {
    float: right; }
  .single-contests .contest--highlight .btn.btn--submit {
    width: 100%; }
  .nav--main li {
    display: block;
    padding: 10px 0;
    text-align: center; } }

@media screen and (max-width: 560px) {
  .contest--highlight .btn,
  .contest--highlight .btn.btn--more {
    float: none;
    display: block;
    width: 100%;
    margin-top: 10px; }
  .nav--holder {
    display: none; }
  #nav-toggle {
    display: inline-block;
    float: right;
    width: 28px;
    height: 30px;
    padding: 38px 0 0 10px;
    cursor: pointer; }
  #nav-toggle span:after,
  #nav-toggle span:before {
    content: "";
    position: absolute;
    left: 0;
    top: -9px; }
  #nav-toggle span:after {
    top: 9px; }
  #nav-toggle span {
    position: relative;
    display: block; }
  #nav-toggle span,
  #nav-toggle span:after,
  #nav-toggle span:before {
    width: 100%;
    height: 5px;
    background-color: #888;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }
  .nav--mobile {
    margin-bottom: 15px; }
  .nav--mobile li {
    display: block;
    text-align: center; }
  .nav--mobile li a {
    display: block;
    padding: 10px 0;
    height: 100%; }
  #sidebar .half {
    width: 100%;
    float: none; }
  .register-form,
  .login-form,
  #wppb-recover-password-button {
    width: 100%;
    float: none;
    margin: 15px 0 30px 0; } }
