/*

Typography

 */

body {
  font-family: $sans;
  color: $main-grey;
  font-size: $init-font-size;
  line-height: $base-line-height;

}

h1, h2, h3, h4, h5 {
  font-weight: 700;
  line-height: 1;
}

h1 {
  font-size: 18px;
  text-transform: uppercase;
  color: $strong-grey;
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 25px;

}

h2 {
  font-size: 16px;
  color: $main-red;
  margin-bottom: 10px;
}

h3 {
  font-size: 13px;
  color: $sub-grey;
}

h4 {
  font-size: 13px;
  color: $main-red;
}

.date {
  color: #000;
  font-size: 11px;
  line-height: 1;
}