/*
VARS
- colors
- fonts
- structure
 */

//Colors
$main-red:    #b30004;

$main-grey:   #7e7e7e;    // body color
$strong-grey: #444444;    // headings, footer nav
$sub-grey:    #737272;    // subheadings, h3
$light-grey:  #F2F2F2;    // body background
$nav-grey:    #F7F7F7;    // nav--main bg


//Fonts
$sans: 'Open Sans', sans-serif;

//Sizes

$init-font-size: 13px;


$base-line-height: 18px;

//Structure
$page-width: 1300px;  //wrap
$main-container-width: 1000px;   //container
$main-content-width: 70%;
$sidebar-width: 30%




