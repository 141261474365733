/*
GLOBALS
  - reset
  - body and main structure layout
  - common elements (links, btns, imgs)
  - forms
*/

//Reset

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
-------------------------
body and main structure |
-------------------------
 */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $light-grey;
  font-family: $sans;
}

.container-wrap {
  background: white;
  max-width: $page-width;
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.container {
  max-width: $main-container-width;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;

}

#main--content {
  box-sizing: border-box;
  width: $main-content-width;
  padding-right: 30px;
  float: left;
}

#sidebar {
  width: $sidebar-width;
  float: right;
}

/*
-----------------
common elements |
-----------------
 */

a {
  text-decoration: none;
  color: inherit;
  &:hover, &:focus {
    color: $main-red;
    outline: none;
  }
}

img {
  max-width: 100%;
}

strong {
  color: $main-red;
  font-weight: bold;
}

p {
  margin-bottom: 1em;
}

div {
  box-sizing: border-box;
}

.btn, .button, .button-primary {
  background: $main-red;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px;
  box-sizing: border-box;
  display: inline-block;

  &:hover, &:focus {
    background: $main-red;
    color: white;

  }

}

.button, .button-primary {
  background: white;
  color: $main-red;
  border: 2px solid $main-red;

}

/*
----------
forms     |
----------
 */

.forms {
  margin-top: 20px;
}

.register-form, .login-form, #wppb-recover-password-button {
  width: 48%;
  margin: 0 1%;
  float: left;
  vertical-align: top;

}

.submit.button, .button-primary {
  text-align: center;
  width: 100%;
}

input {
  padding: 3px 5px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 15px;

}
