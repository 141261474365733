/*

Contest


 */

.showontop {
  border: 2px solid $main-red;
  padding: 15px;
  margin-bottom: 20px;
}

.contest--excerpt {
  margin-top: 20px;
}

.contest--logo {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  float: left;

}

.contest--content a:hover {
  color: black;
}

.contest--highlight {
  background: $nav-grey;
  padding: 15px;
  margin: 15px 0;

  ul {
    display: inline-block;
    max-width: calc(100% - 200px);

    li {
      line-height: 1;
      padding: 2px 0;
    }
  }

}

.contest--highlight {

  .btn {
    float: right;

    &.btn--more {

      margin-right: 10px;
      background: $nav-grey;
      border: 2px solid $main-red;
      padding: 13px 20px;
      box-sizing: border-box;
      color: #b30004;

      &:hover {
        background: $main-red;
        color: white;

      }

    }

  }

}

.contest--recommended {

  .contest--content {

    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d0d0d0;

    &:last-child {
      border-bottom: none;

    }

  }

  .contest--logo {
    width: 125px;
    height: 125px;
    margin-right: 20px;

  }

  .contest--excerpt {

    &:after {
      content: "";
      clear: both;
      width: 100%;
      display: block;
    }

  }

}

.contest--new {

  .contest--content {
    margin-bottom: 15px;
  }

  .contest--logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .contest--title {
    font-weight: normal;
  }

  .contest--teaser {

    &:after {
      content: "";
      clear: both;
      width: 100%;
      display: block;
    }

  }

}

.contest--closing {

  .date {
    margin-bottom: 2px;
  }

  .contest--title {
    font-size: 13px;
  }

}

.contest--full {

  .contest--teaser {
    font-size: 13px;
    margin-top: 0;
  }

  .contest--logo {
    width: 250px;
    margin: 0 20px 15px 0;
  }

}

.contest--listing {

  .contest--logo {
    width: 125px;
    height: 125px;
    margin: 0 20px 15px 0;
  }

  .contest--content {

    &:after {
      content: "";
      clear: both;
      display: block;
      width: 100%;
    }

  }

  .contest--highlight {
    margin: 0;

  }

  .contest--wrap {
    padding-bottom: 20px;
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: 20px;

    &:last-child {
      border-bottom: none;

    }

  }

}

.pagination .nav-links .page-numbers.dots {
  padding-left: 0;
  padding-right: 0;
  background: none;
}


/* CONTEST FINDER STYLINGS */


#search-query {
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 20px;
}
.search-query-items {
  margin-top: 5px;
  margin-bottom: 10px;
}
.search-query-items li {
  display: inline;
}
#search-btn {
  margin-bottom: 20px;
}