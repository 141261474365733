/*

SIDEBAR


 */

.sidebar--ads {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

